body {
    background-color: whitesmoke;
    font-family: Arial, Helvetica, sans-serif
}

.image {
    position: relative;
    height: 200px;
    width: 257px;
}

.image-description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background: rgba(29, 106, 154, 0.72); */
    /* color: rgb(250, 225, 225); */
    color: black;
    visibility: hidden;
    opacity: 0;
    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
    text-align: center;
    padding-top: 20px;
    width: 100%;
}

.image:hover .image-description {
    visibility: visible;
    opacity: 1;
}
.well {
    height: 300px;
    margin-bottom: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .jumbotron {
    height: 500px;
    margin-bottom: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  header {
    margin-top: 5%;
  }
  
  body {
    margin-top: 2%;
  }
  
  .top-react-icons {
    font-size: 5rem;
  }
  
  /* for desktop */
  .whatsapp_float {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 40px;
    right: 100px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 100px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }
  
  .whatsapp-icon {
    margin-top: 100px;
  }
  
  /* for mobile */
  @media screen and (max-width: 767px) {
    .whatsapp-icon {
      margin-top: 10px;
    }
  
    .whatsapp_float {
      width: 60px;
      height: 60px;
      bottom: 20px;
      right: 100px;
      text-align: center;
      font-size: 40px;
    }
  }